@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

:root {
  --primaryColor: #1b57b1;
  --secondaryColor: #2c5ebb;
  --errorColor: #ff0033;
  --successColor: #00cc66;
  --infoColor: #0066ff;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

input {
  font-family: "Inter", sans-serif;
  outline: none;
}

#wrapper {
  max-width: 1600px;
  margin: 18vh auto 0;
  padding: 0 7rem;
  min-height: 76vh;
}

.navbar {
  width: 100%;
  position: fixed;
  top: 2rem;
  padding: 0 5rem;
  margin: auto;
  z-index: 10;
}

.navContainer {
  background-color: white;
  min-height: 7vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 3rem;
  border-radius: 100px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.07);
}

.logo {
  font-size: 1.5rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
}

.navLinksContainer {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.navLink {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.navLink.active {
  color: var(--primaryColor);
  font-weight: 600;
}

.navLink:hover {
  opacity: 0.7;
}

.footer {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-top: solid 1px #efefef;
  position: sticky;
  bottom: 0;
}

.alertMessageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.alertMessageContainer p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  padding-left: 0.5rem;
  width: 100%;
}

.alertMessageContainer svg {
  width: 1.5rem;
  height: auto;
}

.alertMessageContainer.error {
  border: solid 1px var(--errorColor);
}

.alertMessageContainer.error p {
  color: var(--errorColor);
}

.alertMessageContainer.success {
  border: solid 1px var(--successColor);
}

.alertMessageContainer.success p {
  color: var(--successColor);
}

.alertMessageContainer.info {
  border: solid 1px var(--infoColor);
}

.alertMessageContainer.info p {
  color: var(--infoColor);
}

.btnPrimary {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  padding: 12px;
  color: white;
  background-color: var(--primaryColor);
  border: solid 2px var(--primaryColor);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.btnPrimary.full {
  width: 100%;
}

.btnPrimary.disabled {
  background-color: #d9d9d9;
  border: solid 2px #d9d9d9;
  cursor: not-allowed;
}

.btnPrimary:hover {
  opacity: 0.8;
}

.buttonSpinner {
  -ms-animation: btnSpinner 1s infinite linear;
  -moz-animation: btnSpinner 1s infinite linear;
  -webkit-animation: btnSpinner 1s infinite linear;
  -o-animation: btnSpinner 1s infinite linear;
  animation: btnSpinner 1s infinite linear;
}

.link {
  font-size: 1rem;
  font-weight: 400;
  color: var(--primaryColor);
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.linkAlt {
  font-size: 1rem;
  font-weight: 400;
  color: #4d4d4d;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.link:hover,
.linkAlt:hover {
  opacity: 0.7;
}

@keyframes btnSpinner {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 992px) {
  #wrapper {
    max-width: 100%;
    padding: 0 5rem;
  }
  .navbar {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 768px) {
  #wrapper {
    padding: 0 3rem;
  }
  .navbar {
    padding: 0 2rem;
  }
  .navContainer {
    padding: 1.5rem 2rem;
  }
  .navLinksContainer {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  #wrapper {
    padding: 0 1.5rem;
  }
}
