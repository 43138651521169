h1 {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.num-users {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 400;
  background-color: var(--primaryColor);
  color: white;
  padding: 0.25rem 1rem;
  border-radius: 0.75rem;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td:not(:last-child) {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #ececec;
}

.user-table th {
  background-color: #f5f5f5;
  font-size: 0.9rem;
  font-weight: 500;
  color: #888;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-name {
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-bottom: 0.25rem;
}

.logged-in-tag {
  margin-left: 0.65rem;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.15rem 0.65rem;
  background-color: #ececec;
  color: #888;
  border-radius: 1.25rem;
}

.user-email {
  color: #888;
  font-size: 0.85rem;
}

.role {
  display: inline-block;
  padding: 0.4rem 0.75rem;
  border-radius: 1.25rem;
  font-size: 0.85rem;
  font-weight: 500;
  background-color: rgba(27, 87, 177, 0.1);
  color: var(--primaryColor);
}

.action {
  padding: 0.75rem;
  text-align: right;
  border-bottom: 1px solid #ececec;
}

.edit-icon {
  cursor: pointer;
}

.delete-icon {
  cursor: pointer;
  margin-left: 0.75rem;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.delete-icon:hover {
  stroke: var(--errorColor);
}

@media screen and (max-width: 768px) {
  .date-head, .date-row {
    display: none;
  }
  .token-head, .token-row {
    display: none;
  }
}