.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  border: solid 1px #ececec;
  border-radius: 0.75rem;
}

.pageHeader {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.pageHeader h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.otpContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.otpContainer .otpInputContainer {
  height: 85px;
  width: 320px;
}

.otpContainer .otpText {
  font-size: 2rem;
  line-height: 5rem;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.otpContainer .inactiveText {
  background-color: #ececec;
}

.otpContainer .selectedText {
  outline: solid 1px var(--primaryColor);
  color: var(--primaryColor);
}
