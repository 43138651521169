#uploadFileContainer {
  max-width: 900px;
  margin: auto;
  padding-bottom: 3rem;
}

#uploadFileContainer .cardsGroup {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

#uploadFileContainer .cardsGroup .cardContainer {
  display: flex;
  flex-direction: column;
  border: solid 1px #ececec;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

#uploadFileContainer .cardsGroup .cardContainer span {
  font-size: 0.9rem;
  color: #585858;
  margin-bottom: 0.25rem;
}

#uploadFileContainer .cardsGroup p {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0;
}

#uploadFileContainer .cardsGroup .cardContainer h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

#uploadFileContainer .cardsGroup .cardContainer .progressBar {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background-color: #e6e6e6;
}

#uploadFileContainer .cardsGroup .cardContainer .progressBar .progressFill {
  height: 100%;
  width: 0%;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  animation: progress 1s linear;
}

@keyframes progress {
  0% {
    width: 0%;
  }
}

#uploadFileContainer .dragdropContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: dashed 2px #efefef;
  border-radius: 0.25rem;
  width: 100%;
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
}

#uploadFileContainer .dragdropContainer.active {
  background-color: #dfe9fd;
  border: dashed 2px #2c5ebb;
}

#uploadFileContainer .dragdropContainer label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  cursor: pointer;
}

#uploadFileContainer .dragdropContainer svg {
  color: #a0b6df;
  width: 100%;
  height: 80px;
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
}

#uploadFileContainer .dragdropContainer.active svg {
  color: #2c5ebb;
}

#uploadFileContainer .dragdropContainer p {
  font-size: 1rem;
  font-weight: 300;
  margin: 1rem;
}

#uploadFileContainer .dragdropContainer p span {
  text-decoration: underline;
  font-weight: 600;
  color: var(--primaryColor);
}

#uploadFileContainer .dragdropContainer input[type="file"] {
  display: none;
}

#uploadFileContainer .dragDropFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 1.5rem;
  color: #585858;
}

#uploadFileContainer .fileInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #f9f9f9;
  border: solid 1px #efefef;
  border-radius: 0.25rem;
}

#uploadFileContainer .fileInfoContainer .instructions {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 0.5rem 1rem 0.5rem 0;
}

#uploadFileContainer .fileInfoContainer .instructions .instructionHeading {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.75rem;
}

#uploadFileContainer .fileInfoContainer .instructions .instructionHeading img {
  width: 1.5rem;
  height: 100%;
  object-fit: contain;
}

#uploadFileContainer .fileInfoContainer .instructions .instructionHeading h2 {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#uploadFileContainer .fileInfoContainer .instructions p {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}

#uploadFileContainer .fileInfoContainer .removeFileContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 30%;
}

#uploadFileContainer .fileInfoContainer a,
#uploadFileContainer
  .fileInfoContainer
  .removeFileContainer
  input[type="button"] {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: black;
  background-color: white;
  padding: 0.75rem 1rem;
  border: solid 1px #e5e5e5;
  border-radius: 0.25rem;
  width: 100%;
  max-width: 150px;
  cursor: pointer;
}

#uploadFileContainer .fileSubmitContainer {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

#uploadFileContainer .fileSubmitContainer h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

.promptContainer {
  width: 100%;
  margin-top: 2rem;
}

.promptContainer .promptLabel {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

textarea {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  width: 100%;
  padding: 0.5rem 1rem;
  border: solid 1px #e5e5e5;
  border-radius: 0.25rem;
  resize: vertical;
  outline: none;
}

textarea:focus {
  border-color: var(--secondaryColor);
}

.modelAPIResponseContainer {
  width: 100%;
  margin-top: 2rem;
}

.modelAPIResponseContainer h2 {
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

@media screen and (max-width: 992px) {
  #uploadFileContainer {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  #uploadFileContainer {
    max-width: 100%;
  }
  #uploadFileContainer .cardsGroup {
    flex-direction: column;
    gap: 0;
  }
}

@media screen and (max-width: 576px) {
  #uploadFileContainer .cardsGroup .cardContainer {
    padding: 1rem;
    margin-bottom: 1rem;
  }
  #uploadFileContainer .cardsGroup .cardContainer h1 {
    font-size: 1.5rem;
  }
  #uploadFileContainer .cardsGroup .cardContainer span {
    font-size: 0.8rem;
  }
  #uploadFileContainer .cardsGroup p,
  #uploadFileContainer .cardsGroup p .status {
    font-size: 1rem;
  }
  #uploadFileContainer .fileInfoContainer {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  #uploadFileContainer .dragDropFooter p {
    font-size: 0.9rem;
  }
  #uploadFileContainer .fileInfoContainer .instructions,
  #uploadFileContainer .fileInfoContainer a,
  #uploadFileContainer .fileInfoContainer .removeFileContainer {
    width: 100%;
  }
  #uploadFileContainer .fileInfoContainer .instructions {
    padding: 0.5rem 0;
  }
  #uploadFileContainer .fileInfoContainer a {
    align-self: flex-start;
  }
  #uploadFileContainer .fileInfoContainer .removeFileContainer {
    justify-content: left;
  }
  #uploadFileContainer .fileSubmitContainer {
    text-align: left;
  }
}
